<template>
  <app-full-modal :title="$t('subscription.detail.title_modal')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div class="grid grid-cols-3 space-x-3 p-5">
        <div class="space-y-3">
          <p class="row-span-2 text-gray-400 text-xs">
            {{ $t("subscription.detail.term_amount") }}
          </p>

          <p class="col-span-4 font-bold">
            {{ $formats.currency(packages.currency, packages.amount ?? 0.0) }}
          </p>
        </div>

        <div class="space-y-3">
          <p class="col-span-1 text-gray-400 text-xs">
            {{ $t("subscription.detail.term_length") }}
          </p>
          <p class="col-span-4 font-bold">
            {{ packages.frequency_num }}
            {{ packageTerm(packages) }}
          </p>
        </div>

        <div class="space-y-3 mb-10">
          <p class="col-span-1 text-gray-400 text-xs">
            {{ $t("subscription.detail.first_billing_cycle") }}
          </p>
          <p class="col-span-4 font-bold">
            {{ $t("subscription.detail.after") }}
            {{ packages.start_after_num }}
            {{ startBillCycle(packages) }}
          </p>
        </div>
      </div>

      <div class="p-5 space-y-2">
        <p class="font-medium">
          {{ $t("subscription.detail.package_details") }}
        </p>
        <hr />
        <div class="space-y-2">
          <div class="block sm:grid grid-cols-3 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("subscription.package.package_name") }}
            </p>
            <p class="col-span-2">{{ packages.name }}</p>
          </div>
          <div class="block sm:grid grid-cols-3 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("subscription.package.package_description") }}
            </p>
            <p class="col-span-2">
              <app-text-readmore
                :text="packages.description ?? '-'"
                :showReadMore="false"
              />
            </p>
          </div>
          <div class="block sm:grid grid-cols-3 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("subscription.detail.amount_to_pay") }}
            </p>
            <p class="col-span-2">
              {{
                $formats.currency(
                  packages.currency ?? "MYR",
                  getTotalAmount(packages) ?? 0
                )
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-3 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("subscription.detail.package_term_length") }}
            </p>
            <p class="col-span-2">
              {{ packages.frequency_num }}
              {{ packageTerm(packages) }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-3 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("subscription.package.start_package_cycle_after") }}
            </p>
            <p class="col-span-2">
              {{ $t("subscription.detail.after") }}
              {{ packages.start_after_num }}
              {{ startBillCycle(packages) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
import PACKAGE_FREQUENCY from "@/utils/const/package_frequency";
import PACKAGE_START_AFTER_TYPE from "@/utils/const/package_start_after_type";
export default {
  data() {
    return {
      showModalDetail: false,
    };
  },
  props: {
    packages: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
  },
  methods: {
    packageTerm(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return data.frequency_num > 1 ? "days" : "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return data.frequency_num > 1 ? "weeks" : "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return data.frequency_num > 1 ? "months" : "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return data.frequency_num > 1 ? "years" : "year";
      }
    },

    getTotalAmount(data) {
      return data.amount * data.frequency_num;
    },

    startBillCycle(data) {
      switch (data.start_after_type) {
        case PACKAGE_START_AFTER_TYPE.DAYS:
          return data.start_after_num > 1 ? "days" : "day";
        case PACKAGE_START_AFTER_TYPE.WEEKS:
          return data.start_after_num > 1 ? "weeks" : "week";
        case PACKAGE_START_AFTER_TYPE.MONTHS:
          return data.start_after_num > 1 ? "months" : "month";
      }
    },
  },
};
</script>